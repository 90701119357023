import {
    HIDE_MESSAGE,
    ON_HIDE_LOADER,
    ON_SHOW_LOADER,
    SHOW_MESSAGE,
} from "modules/app/types";

import {
    INIT_URL,
    SIGNIN_FACEBOOK_USER_SUCCESS,
    SIGNIN_GITHUB_USER_SUCCESS,
    SIGNIN_GOOGLE_USER_SUCCESS,
    SIGNIN_TWITTER_USER_SUCCESS,
    SIGNIN_USER_SUCCESS,
    SIGNOUT_USER_SUCCESS,
    SIGNUP_USER_SUCCESS,
    LOAD_MENUS_SUCCESS,
    LOAD_ACCESSES_SUCCESS,
} from "./types";
  
const initialSession = {
    validating: true,
    loader: false,
    alertMessage: '',
    showMessage: false,
    initURL: '',
    user: null,
    accessToken: null,
    authenticated: false,
    menus: [],
    accesses: {},
};
  
const sessionReducer = (state = initialSession, action) => {
    
    switch (action.type) {
        
        case SIGNUP_USER_SUCCESS: {
            return {
                ...state,
                validating: false,
                loader: false,
                user: action.payload.user,
                accessToken: action.payload.token,
                authenticated: true
            }
        }

        case SIGNIN_USER_SUCCESS: {
            return {
                ...state,
                validating: false,
                loader: false,
                user: action.payload.user,
                accessToken: action.payload.token,
                authenticated: true
            }
        }

        case INIT_URL: {
            return {
                ...state,
                initURL: action.payload
            }
        }

        case SIGNOUT_USER_SUCCESS: {
            return {
                ...state,
                validating: false,
                user: null,
                authenticated: false,
                accessToken: null,
                initURL: '/',
                loader: false
            }
        }
        
        case SHOW_MESSAGE: {
            return {
                ...state,
                alertMessage: action.payload,
                showMessage: true,
                loader: false
            }
        }

        case HIDE_MESSAGE: {
            return {
                ...state,
                alertMessage: '',
                showMessage: false,
                loader: false
            }
        }

        case SIGNIN_GOOGLE_USER_SUCCESS: {
            return {
                ...state,
                validating: false,
                loader: false,
                user: action.payload.user,
                accessToken: action.payload.token,
                authenticated: true
            }
        }
        case SIGNIN_FACEBOOK_USER_SUCCESS: {
            return {
                ...state,
                validating: false,
                loader: false,
                user: action.payload.user,
                accessToken: action.payload.token,
                authenticated: true
            }
        }
        case SIGNIN_TWITTER_USER_SUCCESS: {
            return {
                ...state,
                validating: false,
                loader: false,
                user: action.payload.user,
                accessToken: action.payload.token,
                authenticated: true
            }
        }
        case SIGNIN_GITHUB_USER_SUCCESS: {
            return {
                ...state,
                validating: false,
                loader: false,
                user: action.payload.user,
                accessToken: action.payload.token,
                authenticated: true
            }
        }
        case ON_SHOW_LOADER: {
            return {
                ...state,
                loader: true
            }
        }
        case ON_HIDE_LOADER: {
            return {
                ...state,
                loader: false
            }
        }
        case LOAD_MENUS_SUCCESS: {
            return {
                ...state,
                menus: action.payload
            }
        }
        case LOAD_ACCESSES_SUCCESS: {
            return {
                ...state,
                accesses: action.payload
            }
        }
        default:
            return state;
    }
}

export { sessionReducer };