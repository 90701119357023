import { LOCATION_CHANGE } from 'connected-react-router';

import {
    SWITCH_LANGUAGE, 
    TOGGLE_COLLAPSED_NAV, 
    WINDOW_WIDTH
} from "./types";

import {
    LAYOUT_TYPE,
    LAYOUT_TYPE_FULL,
    NAV_STYLE,
    // NAV_STYLE_FIXED,
    NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
    THEME_COLOR_SELECTION,
    THEME_COLOR_SELECTION_PRESET,
    THEME_TYPE,
    THEME_TYPE_SEMI_DARK
} from "./constants";

const initialSettings = {
    navCollapsed: true,
    // navStyle: NAV_STYLE_FIXED,
    navStyle: NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
    layoutType: LAYOUT_TYPE_FULL,
    themeType: THEME_TYPE_SEMI_DARK,
    colorSelection: THEME_COLOR_SELECTION_PRESET,
    pathname: '',
    width: window.innerWidth,
    isDirectionRTL: false,
    locale: {
        languageId: 'english',
        locale: 'en',
        name: 'English',
        icon: 'us'
    }
};

const settingsReducer = (state = initialSettings, action) => {
    switch (action.type) {
        // case '@@router/LOCATION_CHANGE':
        case LOCATION_CHANGE:
            const { pathname } = action.payload.location;
            return {
                ...state,
                pathname: pathname,
                navCollapsed: false
            };
        case TOGGLE_COLLAPSED_NAV:
            return {
                ...state,
                navCollapsed: action.navCollapsed
            };
        case WINDOW_WIDTH:
            return {
                ...state,
                width: action.width,
            };
        case THEME_TYPE:
            return {
                ...state,
                themeType: action.themeType
            };
        case THEME_COLOR_SELECTION:
            return {
                ...state,
                colorSelection: action.colorSelection
            };

        case NAV_STYLE:
            return {
                ...state,
                navStyle: action.navStyle
            };
        case LAYOUT_TYPE:
            return {
                ...state,
                layoutType: action.layoutType
            };

        case SWITCH_LANGUAGE:
            return {
                ...state,
                locale: action.payload,
            };
        default:
          return state;
    }
};

export { settingsReducer }