import React, {Component} from "react";
import {Button, Dropdown, Icon, Layout, Menu, message, Popover, Col, Row} from 'antd';
import {connect} from "react-redux";
import CustomScrollbars from "utils/CustomScrollbars";

import languageData from "../languageData";
import SearchBox from "components/SearchBox";
import UserInfo from "components/UserInfo";
import AppNotification from "components/AppNotification";
import MailNotification from "components/MailNotification";
import HorizontalNav from "../HorizontalNav";
import {Link} from "react-router-dom";
import {switchLanguage, toggleCollapsedSideNav} from "../../../actions";
import IntlMessages from "utils/IntlMessages";

const {Header} = Layout;

const menu = (
  <Menu onClick={handleMenuClick}>
    <Menu.Item key="1">Products</Menu.Item>
    <Menu.Item key="2">Apps</Menu.Item>
    <Menu.Item key="3">Blogs</Menu.Item>
  </Menu>
);

function handleMenuClick(e) {
  message.info('Click on menu item.');
}

class InsideHeader extends Component {

  state = {
    searchText: '',
  };

  languageMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll">
      <ul className="gx-sub-popover">
        {languageData.map(language =>
          <li className="gx-media gx-pointer" key={JSON.stringify(language)} onClick={(e) =>
            this.props.switchLanguage(language)
          }>
            <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`}/>
            <span className="gx-language-text">{language.name}</span>
          </li>
        )}
      </ul>
    </CustomScrollbars>);

  updateSearchChatUser = (evt) => {
    this.setState({
      searchText: evt.target.value,
    });
  };


  render() {
    const {locale, navCollapsed} = this.props;

    return (
      <div className="gx-header-horizontal gx-header-horizontal-dark gx-inside-header-horizontal" 
        style={{ position: 'fixed', zIndex: 100, width: '100%' }}
      >
        {/*<div className="gx-header-horizontal-top">
          <div className="gx-container">
            <div className="gx-header-horizontal-top-flex">
              <div className="gx-header-horizontal-top-left">
                <i className="icon icon-alert gx-mr-3"/>
                <p className="gx-mb-0 gx-text-truncate"><IntlMessages id="app.announced"/></p>
              </div>
              <ul className="gx-login-list">
                <li>Login</li>
                <li>Signup</li>
              </ul>
            </div>
          </div>
        </div>*/}


        <Header
          className="gx-header-horizontal-main" >
          <div className="gx-container">
            {/* <div className="gx-header-horizontal-main-flex"> */}              
              <Row className="gx-header-horizontal-main-flex">
                <div className="gx-d-block gx-d-lg-none gx-linebar gx-mr-xs-3 6e">
                  <i className="gx-icon-btn icon icon-menu"
                    onClick={() => {
                      this.props.toggleCollapsedSideNav(!navCollapsed);
                    }}
                  />
                </div>
                <Col lg={3} xs={16}>
                  <Link to="/" className="gx-d-block gx-d-lg-none gx-pointer gx-mr-xs-3 gx-pt-xs-1 gx-w-logo">
                    <img alt="" src={require("assets/images/w-logo.png")}/></Link>
                  <Link to="/" className="gx-d-none gx-d-lg-block gx-pointer gx-logo">
                    <img alt="" src={require("assets/images/logo.png")}/></Link>
                </Col>
                <Col lg={17} className="gx-header-horizontal-nav gx-header-horizontal-nav-curve gx-d-none gx-d-lg-block">
                  {/* <div className="gx-header-horizontal-nav gx-header-horizontal-nav-curve gx-d-none gx-d-lg-block"> */}
                    <HorizontalNav/>
                  {/* </div> */}
                </Col>
                <Col lg={4} push={2}>
                
                  <ul className="gx-header-notifications gx-ml-auto">
                    {/*<li className="gx-notify gx-notify-search">
                      <Popover overlayClassName="gx-popover-horizantal"
                              placement="bottomRight" content={
                        <div className="gx-d-flex"><Dropdown overlay={menu}>
                          <Button>
                            Category <Icon type="down"/>
                          </Button>
                        </Dropdown>
                          <SearchBox styleName="gx-popover-search-bar"
                                    placeholder="Search in app..."
                                    onChange={this.updateSearchChatUser.bind(this)}
                                    value={this.state.searchText}/></div>
                      } trigger="click">

                        <span className="gx-pointer gx-d-block"><i className="icon icon-search-new"/></span>

                      </Popover>
                    </li>*/}

                    {/*<li className="gx-notify">
                      <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={<AppNotification/>}
                              trigger="click">
                        <span className="gx-pointer gx-d-block"><i className="icon icon-notification"/></span>
                      </Popover>
                    </li>*/}

                    {/*<li className="gx-msg">
                      <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight"
                              content={<MailNotification/>} trigger="click">
                    <span className="gx-pointer gx-status-pos gx-d-block">
                    <i className="icon icon-chat-new"/>
                    <span className="gx-status gx-status-rtl gx-small gx-orange"/>
                    </span>
                      </Popover>
                    </li>*/}
                    {/*<li className="gx-language">
                      <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight"
                              content={this.languageMenu()} trigger="click">
                  <span className="gx-pointer gx-flex-row gx-align-items-center"><i
                    className={`flag flag-24 flag-${locale.icon}`}/>
                  </span>
                      </Popover>
                    </li>*/}
                    <li className="gx-user-nav"><UserInfo/></li>
                  </ul>
                </Col>
              </Row>
            {/* </div> */}
          </div>
        </Header>
      </div>
    );
  }
}

const mapStateToProps = ({settings}) => {
  const {locale, navCollapsed} = settings;
  return {locale, navCollapsed}
};
export default connect(mapStateToProps, {toggleCollapsedSideNav, switchLanguage})(InsideHeader);
