import React from 'react';
import {Menu} from "antd";
import {Link} from "react-router-dom";
import IntlMessages from "utils/IntlMessages";
import { connect } from 'react-redux';
import { 
    NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
    THEME_TYPE_LITE 
} from '../../constants';

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
        return "gx-no-header-submenu-popup";
    }
    return "";
};

const renderMenus = ({menus, navStyle}) => {
    const nodes = [];
    menus.forEach(e => {
        let node;
        const isParent = e.children.length > 0;
        if (isParent) {
            node = (
                <MenuItemGroup 
                    key={`group-${e.smn_id}`} 
                    className="gx-menu-group" 
                    title={e.smn_title}>
                    <SubMenu 
                        className={getNavStyleSubMenuClass(navStyle)} 
                        title="A">

                        <SubMenu 
                            className={getNavStyleSubMenuClass(navStyle)} 
                            title="B">

                            <SubMenu 
                                className={getNavStyleSubMenuClass(navStyle)} 
                                title="C">
                    
                            </SubMenu>

                        </SubMenu>

                    </SubMenu>
                    {/* <SubMenu 
                        className={getNavStyleSubMenuClass(navStyle)}
                        title={<span> <i className="icon icon-dasbhoard"/>{e.smn_title}</span>}>

                        

                    </SubMenu> */}
                </MenuItemGroup>
            );
        } else {
            node = (
                <MenuItemGroup 
                    key={e.smn_id} 
                    className="gx-menu-group" 
                    title={e.smn_title}>

                </MenuItemGroup>
                // <Menu.Item 
                //     key={e.smn_id}>
                //     <Link to="/main/dashboard/crypto">
                //         <i className="icon icon-crypto"/>
                //         <span>{e.smn_title}</span>
                //     </Link>
                // </Menu.Item>
            );
        }
        nodes.push(node);
    });
    return nodes;
};



const UserMenu = (props) => {
    const { themeType, navStyle, pathname, menus } = props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];
    
    return (
        <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode="inline">
            
            { renderMenus({menus, navStyle}) }

        </Menu>
    );
};

const mapStateToProps = ({ settings, session }) => {
    const { themeType, navStyle, pathname } = settings;
    const { menus } = session;
    return { menus, themeType, navStyle, pathname }
};

export default connect(mapStateToProps)(UserMenu);