import React from "react";
import {ConnectedRouter} from "connected-react-router";
import {Provider} from "react-redux";
import {Route, Switch} from "react-router-dom";

import "assets/vendors/style";
import "styles/wieldy.less";
import history from "utils/history";
import {setupStore} from "utils/store";
// import configureStore from "utils/store__";
import App from "./index";


const initialState = {}
export const store = setupStore(initialState, history);

const NextApp = () => {
    return (
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <Switch>
                    <Route path="/" component={App}/>
                </Switch>
            </ConnectedRouter>
        </Provider>
    );
}

export default NextApp;
