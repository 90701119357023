import React, {Component} from "react";
import {Avatar, Popover, Modal} from "antd";
import { userSignOutSuccess } from 'modules/auth/actions';
import { connect } from 'react-redux';

const { confirm } = Modal;

class UserProfile extends Component {

    handleLogout = () => {
        const { userSignOutSuccess } = this.props;

        confirm({
            title: 'Logout',
            content: 'Are you sure want to logout?',
            onOk() {
                userSignOutSuccess();
            }
        });
    };

    render() {
        const { user } = this.props;

        const userMenuOptions = (
            <ul className="gx-user-popover">
                <li>My Account</li>
                <li>Connections</li>
                <li onClick={this.handleLogout}>Logout</li>
            </ul>
        );

        return (
            <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
                <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
                    <Avatar src='https://via.placeholder.com/150x150'
                            className="gx-size-40 gx-pointer gx-mr-3" alt=""/>
                    <span className="gx-avatar-name">{user.full_name}<i className="icon icon-chevron-down gx-fs-xxs gx-ml-2"/></span>
                </Popover>
            </div>
        );

    }
}

const mapStateToProps = ({ session }) => {
    const { user } = session;
    return { user };
};

export default connect(mapStateToProps, {userSignOutSuccess})(UserProfile);
