import React, { lazy, Suspense } from 'react';
import CircularProgress from "components/CircularProgress";

const lazyload = (importFunc) => {
    const LazyComponent = lazy(importFunc);

    return props => (
        <Suspense fallback={<CircularProgress className="loading" />}>
            <LazyComponent {...props} />
        </Suspense>
    );
};

export default lazyload;