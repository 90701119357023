import React from 'react';
import axios from 'axios';
import * as storage from 'utils/storage';
import * as Sentry from '@sentry/browser';
import { notification } from 'antd';
import { apiBaseUrl } from './config';

const options = {
    authorization: null,
    session: null, 
};

const api = axios.create({
    baseURL: apiBaseUrl,
    headers: {
        'Content-Type': 'application/json'
    },
    /*
    transformResponse: [
        data => {
            let json;
            
            try {
                json = JSON.parse(data)
            } catch(error) {
                //throw Error(`[requestClient] Error parsing response JSON data - ${JSON.stringify(error)}`)
            }

            return json.data;

            // if (json.success) {
            //     return json.data;
            // } else {
            //     //throw Error(`[requestClient] Request failed with reason -  ${data}`)
            // }
        }
    ]*/
});

api.defaults.xsrfCookieName = 'csrftoken';
api.defaults.xsrfHeaderName = 'X-CSRFToken';

api.interceptors.request.use(config => {
    const commonHeaders = {
        ...config.headers.common,
    };  

    if (!options.authorization) {
        const { accessToken: authorization } = storage.load('session');
        options.authorization = authorization;
    }

    if (options.authorization) {
        commonHeaders.Authorization = 'Token ' + options.authorization;
    }

    if (!options.session){
        const { session } = storage.load('settings');
        options.session = session;
    }

    if (options.session){
         commonHeaders['X-Session'] = options.session;
    }
    
    config.headers.common = commonHeaders;
    return config;
});

api.interceptors.response.use(
    response => {       
        const { 
            status: responseStatus,
            data: { 
                success, 
                status, 
                message = '', 
                data = {}, 
                count, 
                total, 
                page, 
                pages 
            } 
        } = response;

        if ( ! success) {
            if (responseStatus === 204) {
                // valid, do nothing
            } else {
                if (message !== undefined) {
                    Sentry.captureException(message);
                    notification.error({
                        message: 'Error',
                        description: (
                            <React.Fragment>
                                {message.split("\n").map((m,i)=>{
                                    return (<React.Fragment key={i}>
                                        {m}
                                        <br/>
                                    </React.Fragment>);
                                })}
                            </React.Fragment>
                        ),
                    });
                }
            }
        }

        response.message = message;
        response.success = success;
        response.statusCode = status !== undefined ? status : responseStatus;
        response.data = data;
        response.count = count;
        response.total = total;
        response.page = page;
        response.pages = pages;

        return response;
    },
    error => {
        // The request was made and the server responded with a status code
        if (error.response){
            switch (error.response.status) {
                case 401:
                    storage.save('session', {});
                    options.authorization = null;
                    notification.error({
                        message: 'Error',
                        description: "Token expired !",
                    });
                    break;
                
                case 403:
                    notification.error({
                        message: 'Forbidden !',
                        description: "You are not allowed to access this API !",
                    });
                    break;
            
                default:
                    Sentry.captureException(error);
                    notification.error({
                        message: 'Error',
                        description: "Application encountered an error. Don't worry, we've notified about this.",
                    });
                    break;
            }
        // The request was made but no response was received
        } else if(error.request){
            Sentry.captureException(error);
            notification.error({
                message: 'Server Error',
                description: "Application server encountered an error. Don't worry, we've notified about this.",
            });
        // Something happened in setting up the request that triggered an Error
        } else {
            Sentry.captureException(error);
            notification.error({
                message: 'Request Error',
                description: "Application encountered an error. Don't worry, we've notified about this.",
            });
        }
        
        return Promise.reject(error);
    }
);

api.configure = () => {
    const { accessToken: authorization } = storage.load('session');
    const { session } = storage.load('config');
    options.authorization = authorization;
    options.session = session;
};

export default api;
