import React, { useEffect } from "react";
import { Button, Form, Input, Modal } from "antd";
import IntlMessages from "utils/IntlMessages";
import api from "utils/api";

const FormItem = Form.Item;


const ForgotPasswordConfirm = (props) => {

    const handleSubmit = (e) => {
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {
                const qString = props.history.getQuery(); 
                values.token = qString.unique;
                console.log(values);
                api.post('/password_reset/confirm/', values)
                    .then((resp) => {
                        if (resp.status === 200) {
                            Modal.success({
                                content: 'Password successfully updated! ',
                                onOk() {
                                    console.log('redirect to login');
                                    props.history.push('/auth/login');
                                }
                            });
                        }else{
                            Modal.error({
                                content: 'Error, please try again !',                                
                            });
                        }
                    })
            }
        });
    };

    useEffect(()=>{
        const qString = props.history.getQuery(); 
        const email  = qString.email;
        const token  = qString.unique;
        api.post('/password_reset/validate_token/',{
            token: token
        }).then((resp)=>{
            // const {data} = resp;
            if(resp.status !== 200){
                Modal.error({
                    content: 'Invalid token !',
                    onOk(){
                        console.log('redirect to login');
                        props.history.push('/auth/login');
                    }
                });
            }
        })
    },[])

    const { getFieldDecorator } = props.form;

    return (
        <div className="gx-login-container">
            <div className="gx-login-content">

                <div className="gx-login-header">
                    <img src={require("assets/images/logo-2.png")} alt="worksaurus" title="worksaurus" />
                </div>
                <div className="gx-mb-4">
                    <h2>Update Your Password ?</h2>
                    <p><IntlMessages id="app.userAuth.forgot_confirm" /></p>
                </div>

                <Form layout="vertical" onSubmit={handleSubmit} className="gx-login-form gx-form-row0">

                    <FormItem>
                        {getFieldDecorator('password', {
                            rules: [{
                                required: true, message: 'Please specify your new password!',
                            }],
                        })(
                            <Input type="password" placeholder="New password" />
                        )}
                    </FormItem>

                    <FormItem>
                        <Button type="primary" htmlType="submit">
                            <IntlMessages id="app.userAuth.send" />
                        </Button>
                    </FormItem>
                </Form>

            </div>
        </div>
    );
}

const WrappedForgotPasswordConfirmForm = Form.create()(ForgotPasswordConfirm);

export default (WrappedForgotPasswordConfirmForm);
