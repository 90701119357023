import {
    HIDE_MESSAGE,
    ON_HIDE_LOADER,
    ON_SHOW_LOADER,
    SHOW_MESSAGE
} from 'modules/app/types';

import {
    INIT_URL,
    SIGNIN_FACEBOOK_USER,
    SIGNIN_FACEBOOK_USER_SUCCESS,
    SIGNIN_GITHUB_USER,
    SIGNIN_GITHUB_USER_SUCCESS,
    SIGNIN_GOOGLE_USER,
    SIGNIN_GOOGLE_USER_SUCCESS,
    SIGNIN_TWITTER_USER,
    SIGNIN_TWITTER_USER_SUCCESS,
    SIGNIN_USER,
    SIGNIN_USER_SUCCESS,
    SIGNOUT_USER,
    SIGNOUT_USER_SUCCESS,
    SIGNUP_USER,
    SIGNUP_USER_SUCCESS,
    LOAD_MENUS_SUCCESS,
    LOAD_ACCESSES_SUCCESS,
} from './types';

import api from 'utils/api';
import * as storage from 'utils/storage';
  
export const userSignUp = (user) => {
    return {
        type: SIGNUP_USER,
        payload: user
    };
};

export const userSignIn = (payload) => {
    return {
        type: SIGNIN_USER,
        payload: payload
    };
};

export const userSignOut = () => {
    return {
        type: SIGNOUT_USER
    };
};

export const userSignUpSuccess = (payload) => {
    return {
        type: SIGNUP_USER_SUCCESS,
        payload: payload
    };
};

export const userSignInSuccess = (payload) => {
    return {
        type: SIGNIN_USER_SUCCESS,
        payload
    }
};

export const userSignOutSuccess = () => {
    return {
        type: SIGNOUT_USER_SUCCESS,
    }
};

export const showAuthMessage = (message) => {
    return {
        type: SHOW_MESSAGE,
        payload: message
    };
};

export const userGoogleSignIn = () => {
    return {
        type: SIGNIN_GOOGLE_USER
    };
};
export const userGoogleSignInSuccess = (authUser) => {
    return {
        type: SIGNIN_GOOGLE_USER_SUCCESS,
        payload: authUser
    };
};
export const userFacebookSignIn = () => {
    return {
        type: SIGNIN_FACEBOOK_USER
    };
};
export const userFacebookSignInSuccess = (authUser) => {
    return {
        type: SIGNIN_FACEBOOK_USER_SUCCESS,
        payload: authUser
    };
};
export const setInitUrl = (url) => {
    return {
        type: INIT_URL,
        payload: url
    };
};
export const userTwitterSignIn = () => {
    return {
        type: SIGNIN_TWITTER_USER
    };
};
export const userTwitterSignInSuccess = (authUser) => {
    return {
        type: SIGNIN_TWITTER_USER_SUCCESS,
        payload: authUser
    };
};
export const userGithubSignIn = () => {
    return {
        type: SIGNIN_GITHUB_USER
    };
};
export const userGithubSignInSuccess = (authUser) => {
    return {
        type: SIGNIN_GITHUB_USER_SUCCESS,
        payload: authUser
    };
};
export const showAuthLoader = () => {
    return {
        type: ON_SHOW_LOADER,
    };
};

export const hideMessage = () => {
    return {
        type: HIDE_MESSAGE,
    };
};

export const hideAuthLoader = () => {
    return {
        type: ON_HIDE_LOADER,
    };
};

export const loadMenusSuccess = (payload) => {
    return {
        type: LOAD_MENUS_SUCCESS,
        payload: payload
    }
};

export const loadAccessesSuccess = (payload) => {
    return {
        type: LOAD_ACCESSES_SUCCESS,
        payload: payload
    }
};


export const login = ({email, password, csrfkey, csrftoken}) => {
    return dispatch => {
        dispatch(showAuthLoader());

        api.post('/kctauth/login', {
            username: email,
            password: password,
            client: 'browser',
            remember: false,
            captcha: '',
            csrfkey: csrfkey,
            csrftoken: csrftoken
        }, { withCredentials: true })
        .then(res => {
            dispatch(hideAuthLoader());
            
            const { success, message, data: payload } = res;
            
            if(success){
                api.configure();
                dispatch(userSignInSuccess(payload));
            }else{
                dispatch(showAuthMessage(message));
            }
            
            api.configure();
        })
        .catch(error => {
            dispatch(hideAuthLoader());
            api.configure();
        });

    };
}

export const logout = () => {
    return dispatch => {
        return api.get('/kctauth/logout')
            .then(res => {
                const { success } = res;
                if (success) {
                    dispatch(userSignOutSuccess())
                }
            });
    };
};

export const validateSession = () => {
    return dispatch => {
        const { accessToken: token, user } = storage.load('session');
        if (token) {
            return api.get('/kctauth/validate')
                .then(res => {

                    const { success } = res;

                    if (success) {
                        dispatch(userSignInSuccess({ user, token }))
                    } else {
                        dispatch(userSignOutSuccess());
                    }

                    api.configure();
                });
        } else {
            dispatch(userSignOutSuccess());
            api.configure();
        }
    };
};

export const loadMenus = ({ guid }) => {
    
    return dispatch => {
        api.get(`/profile/menus`).then(res => {
            const data = res.data || [];
            dispatch(loadMenusSuccess(data));    
        });
    };
};

export const loadAccesses = ({ guid }) => {

    return dispatch => {
        api.get(`/profile/accesses`).then(res => {
            const data = res.data || [];
            dispatch(loadAccessesSuccess(data));
        });
    };
};