import React, {Component} from "react";
import CustomScrollbars from "utils/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import Auxiliary from "utils/Auxiliary";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
    NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
    NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
    // THEME_TYPE_LITE
} from "../../constants";

import {connect} from "react-redux";
import UserMenu from "./UserMenu";

class SidebarContent extends Component {

    getNoHeaderClass = (navStyle) => {
        if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
            return "gx-no-header-notifications";
        }
        return "";
    };

    render() {
        const {themeType, navStyle, pathname} = this.props;
        const selectedKeys = pathname.substr(1);
        const defaultOpenKeys = selectedKeys.split('/')[1];
        return (
            <Auxiliary>
                <SidebarLogo/>
                <div className="gx-sidebar-content">
                    <div className={`gx-sidebar-notifications ${this.getNoHeaderClass(navStyle)}`}>
                        <UserProfile/>
                        <AppsNavigation/>
                    </div>
                    <CustomScrollbars className="gx-layout-sider-scrollbar">
                        <UserMenu />
                    </CustomScrollbars>
                </div>
            </Auxiliary>
        );
    }
}

SidebarContent.propTypes = {};

const mapStateToProps = ({settings}) => {
    const {navStyle, themeType, locale, pathname} = settings;
    return {navStyle, themeType, locale, pathname}
};

export default connect(mapStateToProps)(SidebarContent);

