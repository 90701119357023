import React from "react";
import { Button, Form, Input, notification } from "antd";
import IntlMessages from "utils/IntlMessages";
import api from "utils/api";

const FormItem = Form.Item;


const ForgotPassword = (props) => {

    const handleSubmit = (e) => {
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {
                api.post('/password_reset/',values)
                .then((resp)=>{
                    notification.success({
                        message: 'Success',
                        description: 'Please check your email !'
                    });
                })
                console.log('Received values of form: ', values);
            }
        });
    };


    const { getFieldDecorator } = props.form;

    return (
        <div className="gx-login-container">
            <div className="gx-login-content">

                <div className="gx-login-header">
                    <img src={require("assets/images/logo-2.png")} alt="worksaurus" title="worksaurus" />
                </div>
                <div className="gx-mb-4">
                    <h2>Forgot Your Password ?</h2>
                    <p><IntlMessages id="app.userAuth.forgot" /></p>
                </div>

                <Form layout="vertical" onSubmit={handleSubmit} className="gx-login-form gx-form-row0">

                    <FormItem>
                        {getFieldDecorator('email', {
                            rules: [{
                                type: 'email', message: 'The input is not valid E-mail!',
                            }, {
                                required: true, message: 'Please input your E-mail!',
                            }],
                        })(
                            <Input type="email" placeholder="E-Mail Address" />
                        )}
                    </FormItem>

                    <FormItem>
                        <Button type="primary" htmlType="submit">
                            <IntlMessages id="app.userAuth.send" />
                        </Button>
                    </FormItem>
                </Form>

            </div>
        </div>
    );
}

const WrappedForgotPasswordForm = Form.create()(ForgotPassword);

export default (WrappedForgotPasswordForm);
