import React from 'react';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import invariant from 'invariant';
import history from './history';
import { checkStore } from './store';
import { settingsReducer } from 'modules/app/reducers';
import { sessionReducer } from 'modules/auth/reducers';
// import { routerReducer } from "react-router-redux";
// import sessionReducer from 'modules/auth/reducers';
import { isEmpty, isFunction, isString } from 'lodash';
import { ReactReduxContext } from 'react-redux';
import hoistNonReactStatics from 'hoist-non-react-statics';

export function setupReducer(injectedReducers = {}) {
    const rootReducer = combineReducers({
        settings: settingsReducer,
        session: sessionReducer,
        // routerReducer: routerReducer,
        // session: sessionReducer,
        router: connectRouter(history),
        ...injectedReducers,
    });
    
    return rootReducer;
}

export function injectReducerFactory(store, isValid) {
    return function injectReducer(key, reducer) {
        if (!isValid) checkStore(store);

        invariant(
            isString(key) && !isEmpty(key) && isFunction(reducer),
            '[utils/reducer] injectReducer: Expected `reducer` to be a reducer function',
        );

        if (
            Reflect.has(store.injectedReducers, key) &&
            store.injectedReducers[key] === reducer
        ) return;

        store.injectedReducers[key] = reducer; 
        store.replaceReducer(setupReducer(store.injectedReducers));
    };
}

export function getInjectors(store) {
    checkStore(store);
    return {
        injectReducer: injectReducerFactory(store, true),
    };
}

export const withReducer = ({ key, reducer }) => WrappedComponent => {
    class ReducerInjector extends React.Component {
        static WrappedComponent = WrappedComponent;
        static contextType = ReactReduxContext;
        static displayName = `withReducer(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

        constructor(props, context) {
            super(props, context);
            getInjectors(context.store).injectReducer(key, reducer);
        }

        render() {
            return <WrappedComponent {...this.props} />;
        }
    }
    
    return hoistNonReactStatics(ReducerInjector, WrappedComponent);
};

export const useReducerInjector = ({ key, reducer }) => {
    const context = React.useContext(ReactReduxContext);
    
    React.useEffect(() => {
        getInjectors(context.store).injectReducer(key, reducer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};