import React, {Component} from "react";
import {connect} from "react-redux";
import URLSearchParams from 'url-search-params'
import {Redirect, Route, Switch} from "react-router-dom";
import {ConfigProvider} from "antd";
import {IntlProvider} from "react-intl";

import AppLocale from "languages";
import MainApp from "./MainApp";
import {
    onLayoutTypeChange, 
    onNavStyleChange, 
    setThemeType
} from "../../actions";

import { validateSession } from "modules/auth/actions";

import {
    LAYOUT_TYPE_BOXED,
    LAYOUT_TYPE_FRAMED,
    LAYOUT_TYPE_FULL,
    NAV_STYLE_ABOVE_HEADER,
    NAV_STYLE_BELOW_HEADER,
    NAV_STYLE_DARK_HORIZONTAL,
    NAV_STYLE_DEFAULT_HORIZONTAL,
    NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
    THEME_TYPE_DARK
} from "../../constants";

import AuthApp from "modules/auth/views/AuthApp";
import CircularProgress from "components/CircularProgress/index";

const RestrictedRoute = ({component: Component, authenticated, ...rest}) =>
    <Route
        {...rest}
        render={props =>
            authenticated
            ? <Component {...props} />
            : <Redirect
                to={{
                    pathname: '/auth/login',
                    state: {from: props.location}
                }}
            />}
    />;

class App extends Component {

    setLayoutType = (layoutType) => {
        if (layoutType === LAYOUT_TYPE_FULL) {
            document.body.classList.remove('boxed-layout');
            document.body.classList.remove('framed-layout');
            document.body.classList.add('full-layout');
        } else if (layoutType === LAYOUT_TYPE_BOXED) {
            document.body.classList.remove('full-layout');
            document.body.classList.remove('framed-layout');
            document.body.classList.add('boxed-layout');
        } else if (layoutType === LAYOUT_TYPE_FRAMED) {
            document.body.classList.remove('boxed-layout');
            document.body.classList.remove('full-layout');
            document.body.classList.add('framed-layout');
        }
    };

    setNavStyle = (navStyle) => {
        if (navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
            navStyle === NAV_STYLE_DARK_HORIZONTAL ||
            navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
            navStyle === NAV_STYLE_ABOVE_HEADER ||
            navStyle === NAV_STYLE_BELOW_HEADER) {
            document.body.classList.add('full-scroll');
            document.body.classList.add('horizontal-layout');
        } else {
            document.body.classList.remove('full-scroll');
            document.body.classList.remove('horizontal-layout');
        }
    };

    componentWillMount() {
        const params = new URLSearchParams(this.props.location.search);

        if (params.has("theme")) {
            this.props.setThemeType(params.get('theme'));
        }
        if (params.has("nav-style")) {
            this.props.onNavStyleChange(params.get('nav-style'));
        }
        if (params.has("layout-type")) {
            this.props.onLayoutTypeChange(params.get('layout-type'));
        }
    }

    componentDidMount() {
        this.props.validateSession();
    }

    render() {
        const {match, location, themeType, layoutType, navStyle, locale, authenticated, initURL, validating} = this.props;
        
        if (validating) {
            return <CircularProgress className="loading" />;
        }

        if (themeType === THEME_TYPE_DARK) {
            document.body.classList.add('dark-theme');
        }

        if (location.pathname === '/') {
            if (authenticated === false) {
                return ( <Redirect to={'/auth/login'}/> );
            } else if (initURL === '' || initURL === '/' || initURL === '/auth/login') {
                return ( <Redirect to={'/projects'}/> );
            } else {
                return ( <Redirect to={initURL}/> );
            }
        }
          
        this.setLayoutType(layoutType);

        this.setNavStyle(navStyle);

        const currentAppLocale = AppLocale[locale.locale];
        
        return (
            <ConfigProvider locale={currentAppLocale.antd}>
                <IntlProvider
                    locale={currentAppLocale.locale}
                    messages={currentAppLocale.messages}>
                    
                    <Switch>
                        <Route path="/auth" component={AuthApp} />
                        <RestrictedRoute 
                            path={`${match.url}`} 
                            authenticated={authenticated} 
                            component={MainApp}/>
                    </Switch>

                </IntlProvider>
            </ConfigProvider>
        )
    }
}

const mapStateToProps = ({settings, session}) => {
    const {locale, navStyle, themeType, layoutType} = settings;
    const {authenticated, initURL, validating} = session;
    return {locale, navStyle, themeType, layoutType, authenticated, initURL, validating}
};

export default connect(mapStateToProps, { 
    setThemeType, 
    onNavStyleChange, 
    onLayoutTypeChange,
    validateSession
})(App);
