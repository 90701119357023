import React from "react";
import { Scrollbars } from "react-custom-scrollbars";

const CustomScrollbars = (props) => {

    return (
        <React.Fragment >
            {props.disabled ?
                <div {...props}></div>
                :
                <Scrollbars  {...props} autoHide
                    renderTrackHorizontal={props => <div {...props}
                        style={{ display: 'none' }}
                        className="track-horizontal" />
                    }
                    renderView={props => {
                        props.style.marginBottom = props.style.marginBottom - 12;
                        return (<div {...props} />);
                    }}
                />
            }
        </React.Fragment>
    );
}

export default CustomScrollbars;
