import React, {Component} from "react";
import {Avatar, Popover, Modal} from "antd";
import {Link} from 'react-router-dom';
import { logout,userSignOutSuccess } from 'modules/auth/actions';
import { connect } from 'react-redux';

const { confirm } = Modal;

class UserInfo extends Component {

    handleLogout = () => {
        const { logout,userSignOutSuccess } = this.props;

        confirm({
            title: 'Logout',
            content: 'Are you sure want to logout?',
            onOk() {
                // userSignOutSuccess();
                logout();
            }
        });
    };

    render() {
        const userMenuOptions = (
            <ul className="gx-user-popover">
                <li><Link to="/profile">My Account</Link></li>
                {/*<li>Connections</li>*/}
                <li onClick={this.handleLogout}>Logout</li>
            </ul>
        );

        return (
            <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions} trigger="click">
                <Avatar style={{color:'#545454'}} icon="user" size="large" className="gx-avatar gx-pointer" alt=""/>
            </Popover>
        )

    }
}

const mapStateToProps = ({ session }) => {
    const { user } = session;
    return { user };
};

export default connect(mapStateToProps, {logout,userSignOutSuccess})(UserInfo);
