import React from "react";
import {Route, Switch} from "react-router-dom";
import lazyload from "utils/lazyload";

const Workspace = ({match}) => (
    <div className="gx-main-content-wrapper">
        <Switch>     
            <Route path={`${match.url}order_simcard`} component={lazyload(() => import('modules/order_simcard/views/OrderSimcard'))}/>
            <Route path={`${match.url}ref_email_list`} component={lazyload(() => import('modules/ref_email_list/views/RefEmailList'))}/>
            <Route path={`${match.url}ref_template`} component={lazyload(() => import('modules/ref_template/views/RefTemplate'))}/>
            <Route path={`${match.url}contact`} component={lazyload(() => import('modules/contact/views/Contact'))}/>
            <Route path={`${match.url}dismantle`} component={lazyload(() => import('modules/dismantle/views/Dismantle'))}/>
            <Route path={`${match.url}example`} component={lazyload(() => import('modules/example/views/Example'))}/>
            <Route path={`${match.url}users`} component={lazyload(() => import('modules/users/views/User'))}/>
            <Route path={`${match.url}profile`} component={lazyload(() => import('modules/profile/views/Profile'))}/>
            <Route path={`${match.url}groups`} component={lazyload(() => import('modules/groups/views/Group'))}/>
            <Route path={`${match.url}workflow`} component={lazyload(() => import('modules/workflow/views/Workflow'))}/>
            <Route path={`${match.url}labels`} component={lazyload(() => import('modules/labels/views/Label'))}/>
            <Route path={`${match.url}settings`} component={lazyload(() => import('modules/settings/views/Setting'))}/>
            <Route path={`${match.url}menus`} component={lazyload(() => import('modules/menus/views/Menu'))}/>
            <Route path={`${match.url}demo_1`} component={lazyload(() => import('modules/demo_1/views/Demo_1'))}/>
            <Route path={`${match.url}demo_invoice`} component={lazyload(() => import('modules/demo_invoice/views/DemoInvoice'))}/>
            <Route path={`${match.url}modules`} component={lazyload(() => import('modules/modules/views/Modules'))}/>
            <Route path={`${match.url}material`} component={lazyload(() => import('modules/material/views/Material'))} />
            <Route path={`${match.url}opportunity`} component={lazyload(() => import('modules/opportunity/views/Opportunity'))} />
            <Route path={`${match.url}projects`} component={lazyload(() => import('modules/demo_1/views/Demo_1'))} />
            <Route path={`${match.url}invoice`} component={lazyload(() => import('modules/demo_invoice/views/DemoInvoice'))} />
            {/* <Route path={`${match.url}ref_segment`} component={lazyload(() => import('modules/ref_segment/views/RefSegment'))}/> */}
            <Route path={`${match.url}ref_industry`} component={lazyload(() => import('modules/ref_industry/views/RefIndustry'))} />
            <Route path={`${match.url}automation`} component={lazyload(() => import('modules/automation/views/Automation'))} />
        </Switch>
    </div>
);

export default Workspace;
