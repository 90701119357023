import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { setupReducer } from './reducer';
import { storageMiddleware } from './storage';
import invariant from 'invariant';
import { conformsTo, isFunction, isObject } from 'lodash';

export const setupStore = (initialState = {}, history) => {
    let composeEnhancers = compose;

    // If Redux Dev Tools and Saga Dev Tools Extensions are installed, enable them
    /* istanbul ignore next */
    if (process.env.NODE_ENV !== 'production' && typeof window === 'object') {
        /* eslint-disable no-underscore-dangle */
        if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
            composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});

    }

    const middlewares = [
        thunk, 
        routerMiddleware(history),
        storageMiddleware
    ];

    const enhancers = [applyMiddleware(...middlewares)];
    
    const store = createStore(
        setupReducer(),
        initialState,
        composeEnhancers(...enhancers),
    );

    store.injectedReducers = {};

    // Make reducers hot reloadable, see http://mxs.is/googmo
    /* istanbul ignore next */
    // if (module.hot) {
    //     module.hot.accept('./reducer', () => {
    //         store.replaceReducer(setupReducer(store.injectedReducers));
    //     });
    // }

    return store;
}

export const checkStore = store => {
    const shape = {
        dispatch: isFunction,
        subscribe: isFunction,
        getState: isFunction,
        replaceReducer: isFunction,
        injectedReducers: isObject,
    };

    invariant(
        conformsTo(store, shape),
        '[utils/store] injectors: Expected a valid redux store',
    );
}