import React from "react";
import {Button, Checkbox, Form, Icon, Input, message} from "antd";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

import {
    login,
    hideMessage,
    showAuthLoader,
    userFacebookSignIn,
    userGithubSignIn,
    userGoogleSignIn,
    userSignIn,
    userTwitterSignIn
} from "../actions";

import IntlMessages from "utils/IntlMessages";
import CircularProgress from "components/CircularProgress/index";

const FormItem = Form.Item;

class SignIn extends React.Component {

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.login(values);
            }
        });
    };

    componentDidUpdate() {
        // if (this.props.showMessage) {
        //     const { alertMessage } = this.props;
        //     message.error(alertMessage.toString());
        //     this.props.hideMessage();
        // }
        
        if (this.props.authenticated !== false) {
            this.props.history.push('/');
        }
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const {loader} = this.props;

        return (
            <div className="gx-app-login-wrap">
                <div className="gx-app-login-container">
                    <div className="gx-app-login-main-content">
                        <div className="gx-app-logo-content">
                            <div className="gx-app-logo-content-bg">
                            </div>
                            <div className="gx-app-logo-wid">
                                <h1><IntlMessages id="app.userAuth.signIn"/></h1>
                                <p><IntlMessages id="app.userAuth.bySigning"/></p>
                                <p><IntlMessages id="app.userAuth.getAccount"/></p>
                            </div>
                            <div className="gx-app-logo">
                                <img alt="example" src={require("assets/images/logo.png")}/>
                            </div>
                        </div>
                        <div className="gx-app-login-content">
                            <Form onSubmit={this.handleSubmit} className="gx-signin-form gx-form-row0">

                                <FormItem>
                                    {getFieldDecorator('email', {
                                        // initialValue: "roso@kct.co.id",
                                        rules: [{
                                            required: true, type: 'email', message: 'The input is not valid E-mail!',
                                        }],
                                    })(<Input placeholder="Email"/>)}
                                </FormItem>

                                <FormItem>
                                    {getFieldDecorator('password', {
                                        // initialValue: "secret",
                                        rules: [{required: true, message: 'Please input your Password!'}],
                                    })(<Input.Password placeholder="Password"/>)}
                                </FormItem>

                                {/*<FormItem>
                                    {getFieldDecorator('remember', {
                                        valuePropName: 'checked',
                                        initialValue: true,
                                    })(<Checkbox><IntlMessages id="appModule.iAccept"/></Checkbox>)}
                                    <span className="gx-signup-form-forgot gx-link">
                                        <IntlMessages id="appModule.termAndCondition"/>
                                    </span>
                                </FormItem>*/}

                                <FormItem>
                                    {getFieldDecorator('remember', {
                                        valuePropName: 'checked',
                                        initialValue: true,
                                    })(
                                        <Checkbox>Remember me</Checkbox>
                                    )}
                                    <Link to="forgot-password" className="gx-login-form-forgot">Forgot password</Link>
                                </FormItem>

                                <FormItem>
                                    <Button type="primary" className="gx-mb-0" htmlType="submit">
                                        <IntlMessages id="app.userAuth.signIn"/>
                                    </Button>
                                    {/*<span><IntlMessages id="app.userAuth.or"/></span> <Link to="/auth/register"><IntlMessages id="app.userAuth.signUp"/></Link>*/}
                                </FormItem>

                                {/*<div className="gx-flex-row gx-justify-content-between">
                                    <span>or connect with</span>
                                    <ul className="gx-social-link">
                                        <li>
                                            <Icon type="google" onClick={() => {
                                                this.props.showAuthLoader();
                                                this.props.userGoogleSignIn();
                                            }}/>
                                        </li>
                                        <li>
                                            <Icon type="facebook" onClick={() => {
                                                this.props.showAuthLoader();
                                                this.props.userFacebookSignIn();
                                            }}/>
                                        </li>
                                        <li>
                                            <Icon type="github" onClick={() => {
                                                this.props.showAuthLoader();
                                                this.props.userGithubSignIn();
                                            }}/>
                                        </li>
                                        <li>
                                            <Icon type="twitter" onClick={() => {
                                                this.props.showAuthLoader();
                                                this.props.userTwitterSignIn();
                                            }}/>
                                        </li>
                                    </ul>
                                </div>*/}

                                {/*<span className="gx-text-light gx-fs-sm"> demo user email: 'roso@kct.co.id' and password: 'secret'</span>*/}
                            
                            </Form>
                        </div>

                        {loader 
                            ? <div className="gx-loader-view"><CircularProgress/></div> 
                            : null}

                    </div>
                </div>
            </div>
        );
    }
}

const WrappedNormalLoginForm = Form.create()(SignIn);

const mapStateToProps = ({session}) => {
    const {loader, alertMessage, showMessage, authenticated} = session;
    return {loader, alertMessage, showMessage, authenticated}
};

export default connect(mapStateToProps, {
    login,
    userSignIn,
    hideMessage,
    showAuthLoader,
    userFacebookSignIn,
    userGoogleSignIn,
    userGithubSignIn,
    userTwitterSignIn
})(WrappedNormalLoginForm);
