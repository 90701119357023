
let apiBaseUrl;

if (process.env.NODE_ENV === 'development') {
    // apiBaseUrl = 'http://127.0.0.1/api';
    apiBaseUrl = 'http://127.0.0.1:8000/api';
}

if (process.env.NODE_ENV === 'production') {
    // apiBaseUrl = 'http://ws.react/api';
    // apiBaseUrl = 'http://127.0.0.1/api';
    // apiBaseUrl = 'http://127.0.0.1:8000/api';
    apiBaseUrl = 'https://acton.kct.co.id/api';
}

module.exports = {
    appName: 'kct@acton',
    apiBaseUrl: apiBaseUrl,
    footerText: 'Copyright Kreasindo Cipta Teknologi © 2020',
}
