import React from 'react';
import { Route, Switch } from 'react-router-dom';
import SignIn from './SignIn';
import SignUp from './SignUp';
import ForgotPassword from './ForgotPassword';
import ForgotPasswordConfirm from './ForgotPasswordConfirm';

const AuthApp = ({ match: {url} }) => {
    return (
        <Switch>
            <Route exact path={`${url}/login`} component={SignIn} />
            <Route exact path={`${url}/register`} component={SignUp} />
            <Route exact path={`${url}/forgot-password`} component={ForgotPassword} />
            <Route exact path={`${url}/forgot-password-confirm`} component={ForgotPasswordConfirm} />
        </Switch>
    );
};

export default AuthApp;