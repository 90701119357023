import React, {useEffect, useState} from "react";
import { useSelector } from "react-redux";
import { Menu, Icon } from "antd";
import { Link } from "react-router-dom";
import IntlMessages from "utils/IntlMessages";
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL
} from "../../constants";

import style from './Topbar.module.less';

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const HorizontalNav = () => {
  const [isTop, setIsTop] = useState(true);
  const detectScroll = () => {
    const onTop = window.scrollY < 70 || window.scrollY == 0;
    setIsTop(window.scrollY < 70)
  }

  useEffect(()=>{
    document.addEventListener('scroll', detectScroll);
    return ()=>{
      document.removeEventListener('scroll', detectScroll);
    }
  },[])

  const navStyle = useSelector(({ settings }) => settings.navStyle);
  const pathname = useSelector(({ settings }) => settings.pathname);
  const menus = useSelector(({ session }) => session.menus);

  const renderItem = (items, level = 0) => {
    const nodes = [];
    items.forEach((e, idx) => {
		let node, icon = (e.smn_icon) ? <Icon type={e.smn_icon} className={style.menuIcon} /> : '';
		if (e.children && e.children.length > 0) {			
			node = (
				<SubMenu className={getNavStyleSubMenuClass(navStyle)} key={`menu-`+e.smn_id}
          popupOffset={[0,0]}
          title={e.smn_title}>		
					{renderItem(e.children, level+1)}	
				</SubMenu>
			);
		} else {
			node = (
      <Menu.Item key={`menu-` + e.smn_id} className={style['menuItem' + level]}>
				<Link to={e.smn_path} className={style.menuIcon}>
				{icon}
				<span className={style.menuIcon}>{e.smn_title}</span>
				</Link>
			</Menu.Item>
			);
		}
		nodes.push(node);
    });

    return nodes;
  };

  const getNavStyleSubMenuClass = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve";
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve";
      case NAV_STYLE_BELOW_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-below-submenu-popup-curve";
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-above-submenu-popup-curve";
      default:
        return "gx-menu-horizontal";

    }
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];
  return (

    <Menu
      defaultOpenKeys={[defaultOpenKeys]}
      selectedKeys={[selectedKeys]}
      mode="horizontal"
      className={style.sticky}
      style={isTop ? { lineHeight: '70px' } : { lineHeight: '42px' }}
      >
      {
        renderItem(menus.sort((a,b)=>{
            if (a.smn_order < b.smn_order) return -1;
            if (a.smn_order > b.smn_order) return 1;
            return 0;
          })
        )
      }      
    </Menu>

  );
};

HorizontalNav.propTypes = {};

export default HorizontalNav;

