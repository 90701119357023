import { createBrowserHistory } from 'history';
import qs from 'qs';
const history = createBrowserHistory({
    basename: process.env.PUBLIC_URL,    
});

history.updateQuery = (object={}) => {
    console.log(history);
    let currentQuery = { ...qs.parse(history.location.search.substr(1)), ...object };
    const qString = qs.stringify(currentQuery);
    return history.push(history.location.pathname + '?' + qString);
}

history.getQuery = (key=false)=>{
    const qString = qs.parse(history.location.search.substr(1));
    return key ? qString[key] : qString;
}

history.toQuery = (object={})=>{
    return qs.stringify(object);
}

export default history;